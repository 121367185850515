import React, {lazy} from "react";
import { Navigate } from "react-router-dom";


import { COMPANY_TYPES as CT } from "../globals/modules";
//Dashboard
const DashboardEcommerce = lazy(() => import("../pages/Dashboard"));
//pages
const Starter = lazy(() => import('../pages/Pages/Starter/Starter'));
const Maintenance = lazy(() => import('../pages/Pages/Maintenance/Maintenance'));
const ComingSoon = lazy(() => import('../pages/Pages/ComingSoon/ComingSoon'));

//login
const Login = lazy(() => import("../pages/Authentication/Login"));
const ForgetPasswordPage = lazy(() => import("../pages/Authentication/ForgetPassword"));
const Logout = lazy(() => import("../pages/Authentication/Logout"));
const Register = lazy(() => import("../pages/Authentication/Register"));

// User Profile
const UserProfile = lazy(() => import("../pages/Authentication/user-profile"));
const Companies = lazy(() => import("../pages/Companies"));
const CompaniesCreate = lazy(() => import("../pages/Companies/Create"));
const CompaniesUpdate = lazy(() => import("../pages/Companies/Update"));
const Fallback = lazy(() => import("../pages/Pages/Fallback"));
const Locations = lazy(() => import("../pages/Locations"));
const Calendar = lazy(() => import("../pages/Calendar"));
const Services = lazy(() => import("../pages/Services"));
const ServicesCreate = lazy(() => import("../pages/Services/Create.Service"));
const ServicesUpdate = lazy(() => import("../pages/Services/Update"));
const LocationsCreate = lazy(() => import("../pages/Locations/Create"));
const LocationsUpdate = lazy(() => import("../pages/Locations/Update"));

const ServiceProviders = lazy(() => import("../pages/ServiceProviders"));
const ServiceProviderCreate = lazy(() => import("../pages/ServiceProviders/Create"));
const ServiceProviderUpdate = lazy(() => import("../pages/ServiceProviders/Update"));

const CompanyUsers = lazy(() => import("../pages/ServiceProviders"));
const CompanyUserCreate = lazy(() => import("../pages/ServiceProviders/Create"));
const CompanyUserUpdate = lazy(() => import("../pages/ServiceProviders/Update"));

const Clients = lazy(() => import("../pages/Clients"));
const ClientsCreate = lazy(() => import("../pages/Clients/Create"));
const ClientsUpdate = lazy(() => import("../pages/Clients/Update"));
const ClientView = lazy(() => import("../pages/Clients/View"));
const ClientGroupView = lazy(() => import("../pages/Clients/GroupView"));
const Transactions = lazy(() => import("../pages/Transactions"));
const TransactionsCreate = lazy(() => import("../pages/Transactions/Create"));

const Costs = lazy(() => import("../pages/Costs"));
const CostsCreate = lazy(() => import("../pages/Costs/Create"));
const CostsUpdate = lazy(() => import("../pages/Costs/Update"));

const Classes = lazy(() => import("../pages/Classes"));
const ClassesCreate = lazy(() => import("../pages/Classes/Create"));
const ClassesUpdate = lazy(() => import("../pages/Classes/Update"));

const Groups = lazy(() => import("../pages/Groups"));
const GroupsCreate = lazy(() => import("../pages/Groups/Create"));
const GroupsUpdate = lazy(() => import("../pages/Groups/Update"));

const Departments = lazy(() => import("../pages/Departments"));
const DepartmentsCreate = lazy(() => import("../pages/Departments/Create"));
const DepartmentsUpdate = lazy(() => import("../pages/Departments/Update"));

const Subscriptions = lazy(() => import("../pages/Subscriptions"));

const Purchases = lazy(() => import("../pages/Purchases"));
const PurchasesCreate = lazy(() => import("../pages/Purchases/Create"));
const PurchasesUpdate = lazy(() => import("../pages/Purchases/Update"));
const CompanySettings = lazy(() => import("../pages/Settings/Company"));
const Scheduler = lazy(() => import("../pages/Scheduler"));
const NotificationsSettings = lazy(() => import("../pages/Notifications"));
const DailyStatistics = lazy(() => import("../pages/Reports/DailySummary"));
const DailyActivityReport = lazy(() => import("../pages/Reports/DailySummary/List"));
const DailyActivityReportPDF = lazy(() => import("../pages/Reports/DailySummary/PDF"));

const ReportsSMSUsage = lazy(() => import("../pages/Reports/Sms"));

const Payments = lazy(() => import("../pages/Reports/Payments"));

const authProtectedRoutes = (role, companyType) => {
	return [
		{ path: "/dashboard", component: <DashboardEcommerce />, role: ["admin", "company"] },

		// reports routes start
		{ path: "/reports/daily-summary", component: <DailyActivityReport />, role: ["admin", "company"], companies: [CT.DENTAL] },
		{ path: "/reports/daily-statistics", component: <DailyStatistics />, role: ["admin", "company"], companies: [CT.DENTAL]},
		{ path: "/reports/daily-activity/pdf", component: <DailyActivityReportPDF />, role: ["admin", "company"], companies: [CT.DENTAL]},

		{ path: "/reports/products", component: <DashboardEcommerce />, role: ["admin"], companies: [CT.DENTAL] },
		{ path: "/reports/services", component: <DashboardEcommerce />, role: ["admin"], companies: [CT.DENTAL] },
		{ path: "/reports/service-providers", component: <DashboardEcommerce />, role: ["admin"], companies: [CT.DENTAL] },

		{ path: "/reports/sms", component: <ReportsSMSUsage />, role: ["admin", "company"], companies: [CT.SPORTS] },

		// reports routes end

		{ path: "/index", component: <DashboardEcommerce />, role: ["admin"] },

		{ path: "/companies", component: <Companies />, role: ["admin"] },
		{ path: "/companies/create", component: <CompaniesCreate />, role: ["admin"] },
		{ path: "/companies/:id", component: <CompaniesUpdate />, role: ["admin"] },

		// Currently not visible anywhere (role empty) until we implement locations by company
		{ path: "/locations", component: <Locations />, role: [""] },
		{ path: "/locations/create", component: <LocationsCreate />, role: [""] },
		{ path: "/locations/:id", component: <LocationsUpdate />, role: [""] },

		{ path: "/services", component: <Services />, role: ["company", "service_provider"], companies: [CT.DENTAL] },
		{ path: "/services/create", component: <ServicesCreate />, role: ["company", "service_provider"], companies: [CT.DENTAL] },
		{ path: "/services/:id", component: <ServicesUpdate />, role: ["company", "service_provider"], companies: [CT.DENTAL]},

		{ path: "/company-users", component: <CompanyUsers />, role: ["company"] },
		{ path: "/company-users/create", component: <CompanyUserCreate />, role: ["company"] },
		{ path: "/company-users/:id", component: <CompanyUserUpdate />, role: ["company"] },

		{ path: "/service-providers", component: <ServiceProviders />, role: ["company"] },
		{ path: "/service-providers/create", component: <ServiceProviderCreate />, role: ["company"] },
		{ path: "/service-providers/:id", component: <ServiceProviderUpdate />, role: ["company"] },

		{ path: "/groups", component: <Groups />, role: ["company"], companies: [CT.SPORTS]},
		{ path: "/groups/create", component: <GroupsCreate />, role: ["company"], companies: [CT.SPORTS]},
		{ path: "/groups/:id", component: <GroupsUpdate />, role: ["company"], companies: [CT.SPORTS]},

		{ path: "/clients", component: <Clients />, role: ["company", "service_provider"] },
		{ path: "/clients/create", component: <ClientsCreate />, role: ["company", "service_provider"] },
		{ path: "/clients/:id", component: <ClientView />, role: ["company", "service_provider"], companies: [CT.DENTAL] },
		{ path: "/clients/:id", component: <ClientGroupView />, role: ["company", "service_provider"], companies: [CT.SPORTS] },
		{ path: "/clients/:id/old", component: <ClientsUpdate />, role: ["company", "service_provider"] },

		{ path: "/users", component: <Locations />, role: ["company"] },
		{ path: "/users/create", component: <Locations />, role: ["company"] },

		{ path: "/calendar", component: <Calendar />, role: ["company", "service_provider"] },

		{ path: "/costs", component: <Costs />, role: ["company", "service_provider"] },
		{ path: "/costs/create", component: <CostsCreate />, role: ["company", "service_provider"] },
		{ path: "/costs/:id", component: <CostsUpdate />, role: ["company", "service_provider"] },

		{ path: "/transactions", component: <Transactions />, role: ["company", "service_provider"], companies: [CT.DENTAL] },
		{ path: "/transactions/create", component: <TransactionsCreate />, role: ["company", "service_provider"], companies: [CT.DENTAL] },

		{ path: "/reports/payments", component: <Payments />, role: ["company"], companies: [CT.SPORTS] },

		{ path: "/departments", component: <Departments />, role: ["company"], companies: [CT.DENTAL]},
		{ path: "/departments/create", component: <DepartmentsCreate />, role: ["company"], companies: [CT.DENTAL]},
		{ path: "/departments/:id", component: <DepartmentsUpdate />, role: ["company"], companies: [CT.DENTAL]},

		{ path: "/subscriptions", component: <Subscriptions />, role: ["company"], companies: [CT.SPORTS]},
		{ path: "/subscriptions/create", component: <DepartmentsCreate />, role: ["company"], companies: [CT.SPORTS]},
		{ path: "/subscriptions/:id", component: <DepartmentsUpdate />, role: ["company"], companies: [CT.SPORTS]},

		{ path: "/classes", component: <Classes />, role: ["company"], companies: [CT.SPORTS]},
		{ path: "/classes/create", component: <ClassesCreate />, role: ["company"], companies: [CT.SPORTS]},
		{ path: "/classes/:id", component: <ClassesUpdate/>, role: ["company"], companies: [CT.SPORTS]},

		{ path: "/purchases", component: <Purchases />, role: ["company", "service_provider"], companies: [CT.DENTAL]},
		{ path: "/purchases/create", component: <PurchasesCreate/>, role: ["company", "service_provider"], companies: [CT.DENTAL] },
		{ path: "/purchases/:id", component: <PurchasesUpdate/>, role: ["company", "service_provider"], companies: [CT.DENTAL] },

		{ path: "/settings/company", component: <CompanySettings />, role: ["company"] },

		//Pages
		{ path: "/pages-starter", component: <Starter />, role: ["admin"] },

		//User Profile
		{ path: "/profile", component: <UserProfile />, role: ["admin"] },
		{ path: "/settings/notifications", component: <NotificationsSettings />, role: ["company"] },

		// eslint-disable-next-line react/display-name
		{
			path: "/",
			exact: true,
			component: <Navigate to={'/dashboard'} />,
			role: ["admin"]
		},
		{ path: "*", component: <Fallback />, role: ["admin"] },
		].filter((route) => {
			let roles = false;

			if(route.role?.includes(role) || ['/', '*'].includes(route.path)) {
				roles = true;
			}

			if(route.companies && !route.companies.includes(companyType)) {
				roles = false;
			}

			return roles;
		}).map((route) => {
			if(role === 'none') {
				return {
					...route,
					element: (<Starter />)
				}
			}
			return route;
	});
};

const publicRoutes = [
	// Authentication Page
	{ path: "/logout", component: <Logout /> },
	{ path: "/login", component: <Login /> },
	{ path: "/forgot-password", component: <ForgetPasswordPage /> },
	{ path: "/register", component: <Register /> },

	//AuthenticationInner pages
	{ path: "/pages-maintenance", component: <Maintenance /> },
	{ path: "/pages-coming-soon", component: <ComingSoon /> },
	{ path: "/invitation/:token", component: <Register /> },
	{ path: "/schedule/:companyId", component: <Scheduler /> },

	{ path: "/", component: <Fallback /> },
];

export { authProtectedRoutes, publicRoutes };
