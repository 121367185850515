import { APIClient } from "./api_helper";

export const getAllGroupsApi = async (companyId) => {
	return await new APIClient().get(`/company/${companyId}/groups`);
}

export const getCourseGroupsApi = async (companyId, groupId) => {
	return await new APIClient().get(`/company/${companyId}/groups/${groupId}`);
}

export const getCourseGroupApi = async (companyId, courseId, groupId) => {
	return await new APIClient().get(`/company/${companyId}/courses/${courseId}/groups/${groupId}`);
}

export const createCourseGroupApi = async (companyId, courseId, data) => {
	return await new APIClient().post(`/company/${companyId}/courses/${courseId}/groups`, data);
}

export const updateCourseGroupApi = async (companyId, groupId, data) => {
	return await new APIClient().put(`/company/${companyId}/groups/${groupId}`, data);
}

export const deleteCourseGroupApi = async (companyId, groupId) => {
	return await new APIClient().delete(`/company/${companyId}/groups/${groupId}`);
}

export const getGroupCoachesApi = async (companyId, groupId) => {
	return await new APIClient().get(`/company/${companyId}/groups/${groupId}/coaches`);
}
